<template>
  <div id="Search-view" class="my-5">
    <div class="container-fluid" v-if="!Singleproduct">
      <center>
        <div class="findgft">
          <h1 class="display-1 mb-0" style="font-weight: 400">
            <span>
              <img
                class="giftbox-img mr-2"
                src="@/assets/site/images/gift-box-left.svg"
                alt=""
            /></span>
            gifts
            <span>
              <img
                class="giftbox-img ml-2"
                src="@/assets/site/images/gift-box-right.svg"
                alt=""
            /></span>
          </h1>
        </div>
        <div class="mt-5 mb-5" v-if="keyword == 'gift card'">
          <b-input-group class="mt-3 col-md-6">
            <b-form-input
              v-model="giftCardKeyword"
              @keyup.enter="giftCard_search"
              placeholder="Enter a keyword"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                variant="btn btn-info"
                @click="giftCard_search"
                v-if="!search_load"
                >Search</b-button
              >
              <b-button variant="buttonload" v-if="search_load"
                ><i class="fa fa-refresh fa-spin"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </center>
      <div class="product-slider product-slider-1">
        <div
          id="sm_filterproducts_1610096371335085279"
          class="products-grid mt-4"
        >
          <center v-if="products.length < 1" class="mt-4">
            <h3>
              <strong class="text-danger mx-3"
                >There are no Gifts based on your keywords,<br />
                please refine your search!!!</strong
              >
            </h3>
          </center>
          <div class="row">
            <div v-if="search_loading" class="col-md-12 row">
              <span class="col-md-2"></span>
              <img
                src="@/assets/site/images/loader.gif"
                class="img-fluid loader-width col-md-8"
                alt
              />
              <span class="col-md-2"></span>
            </div>
            <div v-else class="col-md-3" v-for="product in products">
              <div
                class="owl-item active wizard-box"
                :id="'product_' + product.sku"
              >
                <div
                  @click="ViewProduct(product)"
                  class="item product product-item"
                >
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <div class="label-sale pull-right"></div>
                      <a class="product photo product-item-photo" tabindex="-1">
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%"
                          >
                            <span v-if="product.imageurl">
                              <img
                                class="wizard-image-photo lazyload"
                                :src="product.imageurl"
                                @error="() => (product.imageurl = null)"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt=""
                            /></span>
                            <span v-else>
                              <img
                                class="wizard-image-photo lazyload"
                                src="@/assets/site/images/noimage.jpeg"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt=""
                            /></span>
                          </span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <a
                          data-post=""
                          @click.stop="savetoFavourite(product)"
                          class="action towishlist btn-action link-wishlist"
                          data-action="add-to-favourite"
                          title="Add to Favourite"
                        >
                          <span>Add to Favourite</span>
                        </a>
                        <a
                          data-post=""
                          @click.stop="selectOccasion(product)"
                          class="action tocart primary btn-action btn-cart"
                          data-action="add-to-wishlist"
                          title="Add to WishList"
                        >
                          <span>Add to Wish List</span>
                        </a>
                        <a
                          class="action tocompare btn-action link-compare"
                          @click.stop="compareProduct(product)"
                          data-post=""
                          title="Add to Compare"
                        >
                          <span>Add to Compare</span>
                        </a>
                        <a
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click.stop="ViewProduct(product)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ product.productname }}
                        </a>
                      </h2>

                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper"
                          >
                            <span v-if="product.price != null" class="price">
                              USD {{ product.price }}
                            </span>
                            <span v-else class="price">
                              {{ product.price["@currency"] }}
                              {{ product.price["#text"] }}
                            </span>
                            <span class="price">
                              {{ product.price["@currency"] }}
                              {{ product.price["#text"] }}
                            </span>
                          </span>
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label"><span>Rating:</span></span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%"><span>87%</span></span>
                          </div>
                        </div>
                        <div class="reviews-actions">
                          <a class="action view">
                            &nbsp;<span>Review </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!search_loading"
          class="row my-4 d-flex justify-content-center"
        >
          <app-pagination
            :currentPage="page"
            :lastPage="totalPages"
            :hasNextPage="page !== totalPages"
            :changePage="changePage"
          ></app-pagination>
        </div>
      </div>
    </div>
    <div>
      <occasion-modal v-on:savetoWishlist="savetoWishlist"></occasion-modal>
    </div>
  </div>
</template>
<script>
import OccasionModal from "@/views/site/OccasionModal";
import AppPagination from "@/views/site/blocks/Pagination";

export default {
  name: "SearchView",
  metaInfo: {
    title: "Search",
    meta: [
      {
        name: "description",
        content:
          "Explore our extensive collection of gifts with ease! Use our search feature to find the perfect gift for any occasion. Whether it's for birthdays, holidays, or special moments, our platform offers a variety of products to choose from. Refine your search and discover unique options today!",
      },
      {
        name: "keywords",
        content:
          "gift search, gift ideas, gift cards, unique gifts, birthday gifts, holiday gifts, wishlist, product reviews, online gifts, gift categories, gift shopping, customer favorites, find gifts, refine search, suggested gifts",
      },
    ],
  },
  data() {
    return {
      loader: false,
      errors: [],
      sproduct: [],
      show: false,
      keyword: null,
      products: [],
      Singleproduct: false,
      category: "",
      page: 1,
      totalPages: null,
      wishlist_product: null,
      request_body: null,
      giftCardKeyword: null,
      search_load: false,
      search_loading: false,
      isGiftCard: false,
    };
  },
  components: {
    OccasionModal,
    AppPagination,
  },
  created() {
    this.$store.state.wizard_type = "User";
    this.isGiftCard = this.$store.state.isGiftCard ?? false;
    this.getKeyword();
  },
  methods: {
    getKeyword() {
      this.keyword = this.$store.state.keyword;
      this.page = this.$store.state.page;
      this.request_body = this.$store.state.request_body;

      if (this.$store.state.products) {
        this.products = this.$store.state.products;
      }
      this.category = this.$store.state.category;
      this.totalPages = this.$store.state.totalPages;
    },
    ViewProduct(product) {
      this.$store.state.single_product = product;
      this.$router.push({
        name: "SearchSingle",
        params: {},
      });
      this.sproduct = product;
    },
    changePage(page) {
      !this.search_loading && this.moreProducts(page);

      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    },
    moreProducts(page) {
      this.search_loading = true;

      const fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      let payload;
      if (this.isGiftCard)
        payload = {
          keyword: "gift card",
          type: "gift_card",
          page,
        };
      else
        payload = {
          keyword: this.keyword,
          category: this.category,
          type: "keyword",
          page,
        };
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          this.products = data.response.item;
          this.$store.state.products = data.response.item;

          this.search_loading = false;
          if (this.isGiftCard) {
            this.totalPages = data.response.TotalPages;
          } else {
            this.totalPages = data.response.pagination.max_page;
          }
          this.page = page;
          this.$store.state.page = page;
        })
        .catch(function(err) {
          this.search_loading = false;
          console.log("Fetch Error :-S", err);
        });
    },
    selectOccasion(product) {
      this.wishlist_product = product;
      this.$bvModal.show("occasion-modal");
    },
    savetoWishlist(occasion) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        this.$bvModal.hide("occasion-modal");
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: this.wishlist_product.productId,
            product: this.wishlist_product,
            user_id: user_id,
            clickstream_data: null,
            user_action: "AddToWishlist",
            wishlist_type: "User",
            occasion_id: occasion.id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;

              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
        this.wishlist_product = null;
      }
    },
    savetoFavourite(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: product.productId,
            product: product,
            user_id: user_id,
            clickstream_data: null,
            user_action: "AddToFav",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;

              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.$swal.fire("Error", data.message, "error");
            } else {
              // this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    compareProduct(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var productId = product.mid + "-" + product.linkid;
        var fetch_url = process.env.VUE_APP_URL + "customer/compare";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: productId,
            product: product,
            user_id: user_id,
            user_action: "compare",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    giftCard_search() {
      this.$store.state.pet_id = null;
      this.$store.state.occasion_id = null;
      this.search_load = true;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          keyword: "gift card " + this.giftCardKeyword,
          type: "gift_card",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.search_load = false;
          var products = data.response;
          const id = Math.random()
            .toString(36)
            .slice(2);
          this.$store.state.keyword = "gift card";
          this.$store.state.products = data.response.item;
          this.$store.state.totalPages = data.response.TotalPages;
          this.$store.state.page = 1;
          this.$router.replace({
            name: "SearchView",
            params: {
              id,
            },
          });
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
  },
};
</script>
